import React from 'react';
import "./Auth.scss";
import { Link } from 'react-router-dom';

function SignIn() {
  return (
    <div className='body'>
      <div className="shadow-sm auth-container">
        <img src="/cleverpeblack.png" alt="cleverpe" className="logo" />
        <h3>Be Clever Pe Later</h3>
        <h6>Sign In</h6>
        
        {/* Direct prompt to access CleverPe 2.0 */}
        <div className="info-box mb-3">
          Your account has been upgraded. <br></br>Please <a href="https://merchant.cleverpe.com"><b>click here</b></a> to access <b>CleverPe 2.0</b>.
        </div>

        <p className="grey subtitle my-3">
          By continuing, you agree to CleverPe <Link to="/termsandconditions">Terms and Conditions</Link> of Use and <Link to="/privacypolicy">Privacy Policy</Link>.
        </p>
        <hr />
        <p className='grey subtitle mt-3'>
          ©2022 Cleverpe Technologies Private Limited
        </p>
      </div>
    </div>
  );
}

export default SignIn;
